/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    var wahyu = {
        sliderWorks: function () {

            // slider for works list
            $('.tc_works_slider').not('.slick-initialized').slick({
                // mobileFirst: true,
                // settings: "unslick",
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                autoplay: true,
                rows: 0,
                autoplaySpeed: 7000,
                lazyLoad: 'ondemand',
                prevArrow: "<span class='slick_icon slick_icon-left slick-prev'></span>",
                nextArrow: "<span class='slick_icon slick_icon-right slick-next'></span>",
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        },
        RWDmobile: function () {
            if ($(window).outerWidth() <= 767) {
                $('.tc_works_slider .tc_works_image').each(function(){
                    var d_lazy = $(this).data('lazy');
                    $(this).attr('src', d_lazy);
                });
            } else {
                this.sliderWorks();
            }
        }
        // tes: function () {
        //     console.log('tes from external method');
        // }
    };
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                // slider for details works
                $('.tc_detail_slider').not('.slick-initialized').slick({
                    slidesToScroll: 1,
                    variableWidth: true,
                    rows: 0,
                    // centerMode: true,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 7000,
                    prevArrow:"<span class='slick_icon slick_icon-left slick-prev'></span>",
                    nextArrow:"<span class='slick_icon slick_icon-right slick-next'></span>",
                    responsive: [
                        {
                            breakpoint: 992,
                            settings:
                            {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings:
                            {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                // wraping class
                $('.tc_home').closest('body').find('#tc_footer, .tc_heading').addClass('fixed-layout');
                $('.tc_error').closest('body').find('main').addClass('only-sia');
                $('#user-login').closest('body').find('main').addClass('only-sia');
                $('#user-login').closest('.tc_wrapper_default').wrapInner($( '<div class="tc_login"><div class="tc_login_master"><div class="tc_login_block"></div></div></div>' ));
                $('#user-login').closest('.tc_login_block').prepend('<h2 class="tc_login_heading">LOGIN</h2>');

                // smooth scrolling bar (append on details works)
                $(".customScroll").mCustomScrollbar({
                    theme:"dark-thin",
                    autoHideScrollbar: true
                });

                // call own function
                // wahyu.sliderWorks();

                // function for RWD
                wahyu.RWDmobile();

                // resize function
                $(window).resize(function () {
                    // wahyu.sliderWorks();
                    wahyu.RWDmobile();
                });

                $(window).on('load', function(){
                    // slider for home
                    $('.tc_home_slider').slick({
                        fade: true,
                        infinite: true,
                        rows: 0,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        cssEase: 'cubic-bezier(0.94, 0.4, 0.49, 0.66)',
                        prevArrow: "<span class='slick_icon slick_icon-left slick-prev'></span>",
                        nextArrow: "<span class='slick_icon slick_icon-right slick-next'></span>"
                    });
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
